<template>
  <!-- <div class="row" style="border: 2px solid white; height: 200px;"></div> -->
  <div class="card">
    <div class="card-body">
      <form v-on:submit.prevent="makePayment" class="row g-3" ref="paymentForm">
        <div class="form-group col-6">
          <label for="vendor" class="form-label">
            {{ $t("claims.processing.payment.vendor", { text: "Payment" }) }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="text"
            id="vendor"
            class="form-control"
            v-model="payment.vendor"
            placeholder="Cashflow / DCBL / ZZPS / Bank"
            required
          />
        </div>
        <div class="form-group col-6">
          <label for="amount" class="form-label">
            {{ $t("claims.processing.payment.amount", { text: "Payment" }) }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="number"
            class="form-control"
            id="amount"
            v-model="payment.value"
            placeholder="0.00"
            min="0.01"
            step="0.01"
            required
          />
        </div>
        <div class="form-group col-6">
          <label for="received" class="form-label">
            {{ $t("claims.processing.payment.received", { text: "Payment" }) }}
            <span class="text-danger">*</span>
          </label>
          <input
            type="date"
            class="form-control"
            id="received"
            v-model="payment.received"
            placeholder=""
            required
          />
        </div>
        <div class="form-group">
          <label for="notes" class="form-label">
            {{ $t("claims.processing.payment.notes") }}
            <span class="text-danger">*</span>
          </label>
          <textarea
            type="text"
            class="form-control"
            id="notes"
            v-model="payment.notes"
            required
          />
        </div>
        <div class="flex-center">
          <!-- Is the form to create a new payment method or to make a new payment -->
          <button class="btn btn-primary m-t-xxs" type="submit">
            {{ $t("claims.processing.payment.make_payment") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import ClaimsService from "../../../../../services/claims.service";
import { DateTime } from "luxon";
export default {
  name: "NewPayment",
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data() {
    return {
      payment: {
        vendor: "",
        value: 0.0,
        received: "",
        notes: "",
      },
      loading: true,
      creating: false,
    };
  },
  methods: {
    makePayment() {
      this.creating = true;
      this.payment.received = DateTime.fromFormat(
        this.payment.received,
        "yyyy-MM-dd"
      ).toFormat("dd/MM/yyyy");
      let data = this.payment;
      data.client_id = this.client_id;

      ClaimsService.createPayment(this.claim.id, data)
        .then((response) => {
          this.$success(
            this.$t("claims.processing.payment.make_payment_success")
          );
          this.creating = false;
          this.$refs.paymentForm.reset();
        })
        .catch((error) => {
          this.$error(
            this.$t("claims.processing.payment.make_payment_error"),
            error
          );
          this.creating = false;
        });
    },
  },
};
</script>

<style scoped>
.nav-bars {
  border-bottom: none;
}
</style>
