<template>
  <slot></slot>

  <h2 class="sub-heading">{{ $t("claims.processing.outstanding_losses") }}</h2>
  <div class="row">
    <div class="col">
      <div class="card invoice col-11" style="margin: auto">
        <div class="card-body">
          <div class="invoice-header">
            <div class="row">
              <div class="col-9">
                <p style="color: #fff">FINANCIAL INFORMATION</p>
              </div>
              <div class="col-3">
                <span class="invoice-issue-date">
                  <timestamp
                    :time="claim.date_received"
                    format="dd/MM/yyyy"
                  ></timestamp>
                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="table-responsive">
              <div class="table invoice-table">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col" class="desc">
                      {{ $t("labels.description") }}
                    </th>
                    <th scope="col"></th>
                    <th scope="col" class="qty">{{ $t("labels.quantity") }}</th>
                    <th scope="col">{{ $t("labels.amount") }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">1.</td>
                    <td>{{ claim.fuel }} (Pump {{ claim.pump }})</td>
                    <td></td>
                    <td>{{ claim.litres }} Liters</td>
                    <td>£{{ claim.value }}</td>
                  </tr>
                  <tr v-if="claim.fuel_second !== null && !savingFuel">
                    <td scope="row">2.</td>
                    <td>
                      {{ claim.fuel_second }} (Pump {{ claim.pump_second }})
                    </td>
                    <td></td>
                    <td>{{ claim.litres_second }} Liters</td>
                    <td>£{{ claim.value_second }}</td>
                  </tr>
                </tbody>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="row invoice-summary">
            <div class="col-lg-5">
              <div class="invoice-info">
                <p>
                  {{ $t("claims.processing.reference-number") }} :
                  <span> {{ claim.reference }} </span>
                </p>
                <p>
                  {{ $t("claims.processing.vehicle-registration") }} :
                  <span> {{ claim.vehicle.plate }} </span>
                </p>
              </div>
            </div>
            <div class="col-lg-3"></div>
            <div class="col-lg-4">
              <div class="invoice-info">
                <p>
                  {{ $t("labels.sub_total") }} :
                  <span> £{{ amountToCollect.toFixed(2) }}</span>
                </p>
                <p>
                  {{ $t("labels.payment_made") }} :
                  <span :class="{ 'text-success': paymentsMade > 0 }">
                    - £{{ paymentsMade.toFixed(2) }}
                  </span>
                </p>
                <p class="total">
                  {{ $t("sites.invoice.total").toUpperCase() }} :
                  <span> £{{ withDeductions.toFixed(2) }} </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Timestamp from "../../../../../components/Timestamp.vue";
import { DateTime } from "luxon";

export default {
  name: "CnafDetails",
  components: {
    Timestamp,
  },
  props: {
    claim: Object,
    client_id: Number | String,
  },
  data() {
    return {
      editFuelModal: null,
      editSecondFuelModal: null,
      fuelTypes: ["Petrol", "Diesel", "AdBlue", "Liquid Petroleum Gas (LPG)"],
      reason: "",
      savingFuel: false,
      savingFuelSecond: false,
    };
  },
  mounted() {
    this.editSecondFuelModal = new bootstrap.Modal(
      document.getElementById("editSecondFuelModal")
    );
    this.editFuelModal = new bootstrap.Modal(
      document.getElementById("editFuelModal")
    );
  },
  methods: {
    resetForm() {
      document.getElementById("FuelForm").reset();
      document.getElementById("editSecondFuelModal").hide();
    },
  },
  computed: {
    amountToCollect: function () {
      let amount = parseFloat(this.claim.value);

      if (this.claim.fuel_second !== null) {
        amount += parseFloat(this.claim.value_second);
      }

      return parseFloat(amount);
    },
    withDeductions: function () {
      let amount = parseFloat(this.amountToCollect);
      amount -= parseFloat(this.paymentsMade);

      return parseFloat(amount);
    },
    paymentsMade: function () {
      let value = 0;

      this.claim.payments.forEach((payment) => {
        value += parseFloat(payment.amount);
      });

      if (this.claim.status === "Paid on Site") {
        value = this.amountToCollect;
      }

      return parseFloat(value);
    },
    dueDate: function () {
      return DateTime.fromSeconds(this.claim.dates.date_letter_sent || 0)
        .plus({ days: 17 })
        .toSeconds();
    },
    overDue: function () {
      return DateTime.local().toSeconds() > this.dueDate;
    },
    nearlyDue: function () {
      return (
        DateTime.local().toSeconds() < this.dueDate &&
        DateTime.local().toSeconds() >
          DateTime.fromSeconds(this.dueDate).minus({ days: 4 }).toSeconds()
      );
    },
  },
};
</script>

<style scoped>
.col-6 {
  width: 48%;
}

.card.invoice {
  max-width: 700px;
}

.invoice-header {
  padding: 15px 20px;
  font-size: 18px;
  font-weight: 500;
  background-color: #6939ce;
}

.col-9 p {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 500;
}

thead {
  /* background-color: var(--stroke-color-primary); */
}

td {
  /* background-color: var(--bg-color-secondary); */
  border-color: transparent;
  border-style: none;
  border-width: 0;
}

.table-invoice td {
  text-align: left;
}

th.desc {
  width: 40%;
}

th.qty {
  width: 30%;
}

.invoice-table th:not(:first-child):not(:nth-child(2)),
.invoice-table td:not(:first-child):not(:nth-child(2)) {
  text-align: left;
}

.new-fuel-btn {
  /* background-color: var(--bg-color-tertiary); */
  padding: 0 50px;
}

.new-fuel-btn:hover {
  /* background-color: rgba(24, 24, 33, .6); */
  cursor: pointer;
}

.total {
  padding: 10px;
  background-color: var(--utility-red);
  border-radius: 5px;
}

.total p {
  margin: 0px;
  font-weight: 500;
}

.form-control,
.form-select {
  /* background-color: #232330; */
}

.form-control::placeholder {
  /* color: rgb(74, 78, 83); */
}

.material-icons {
  cursor: pointer;
}
</style>
