<template>
  <div class="row">
    <!-- <div class="card widget widget-list">
            <ul class="nav nav-pills nav-justified">
                <li class="nav-item">
                    <a 
                        href="javascript:void(0);"
                        class="nav-link"
                        aria-current="page"
                        :class="{'active': tabs.list}"
                        role="tab"
                        :aria-selected="tabs.list"
                        @click="selectTab('list')"> Payments </a>
                </li>
                
                <li class="nav-item">
                    <a 
                        href="javascript:void(0);"
                        class="nav-link"
                        aria-current="page"
                        :class="{'active': tabs.createNew}"
                        role="tab"
                        :aria-selected="tabs.createNew"
                        @click="selectTab('createNew')"> New Payment </a>
                </li>
            </ul>
        </div>     -->
    <ul class="nav nav-tabs mb-3" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          data-bs-toggle="tab"
          type="button"
          role="tab"
          :aria-selected="tabs.list"
          :class="{ active: tabs.list }"
          @click="selectTab('list')"
        >
          Payments
        </button>
      </li>

      <!-- <li class="nav-item" role="presentation">
                <button class="nav-link" data-bs-toggle="tab" type="button" role="tab" :aria-selected="tabs.createNew" :class="{'active': tabs.createNew}" @click="selectTab('createNew')"> New Payment </button>
            </li> -->
    </ul>

    <div
      class="tab-pane fade"
      v-show="tabs.list"
      :class="{ 'show active': tabs.list }"
      role="tabpanel"
    >
      <payment-list :claim="claim" :client_id="client_id"></payment-list>
    </div>
    <!-- <div class="tab-pane fade" v-show="tabs.createNew" :class="{'show active': tabs.createNew}" role="tabpanel">
            <new-payment :claim="claim" :client_id="client_id"></new-payment>
        </div> -->
  </div>
</template>

<script>
import ClaimService from "../../../../../services/claims.service";
import PaymentList from "./PaymentList.vue";
import NewPayment from "./NewPayment.vue";

export default {
  name: "ClaimPayments",
  props: {
    claim: Object,
    client_id: String | Number,
  },
  components: { PaymentList, NewPayment },
  data() {
    return {
      tabs: {
        list: true,
        createNew: false,
      },
      // payments: []
    };
  },
  mounted() {
    // console.log(this.claim.payments);
    // this.getPayments();
  },
  methods: {
    // getPayments() {
    //     ClaimService.getClaimPayments(this.claim.id, this.client_id)
    //     .then(response => {
    //         this.payments = response.data;
    //         console.log(this.payments);
    //     })
    //     .catch(error => {
    //         this.$error(this.$t('claims.processing.payment.payment_error'), error);
    //     })
    // },
    selectTab(tab) {
      for (let elem in this.tabs) {
        this.tabs[elem] = false;
      }
      this.tabs[tab] = true;
    },
  },
};
</script>

<style></style>
